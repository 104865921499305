import { gql } from '@apollo/client';

// Individuelle, für die Listen benötigte queries


// Für Gasdruckprüfungen gehen wir über Parzellen
export const GET_PAECHTER = gql`
    query GetPaechter {
        paechter {
            name
            vorname
            telefonFestnetz
            telefonMobil
            weitereKontaktpersonen
            pachtPaechter {
                pachtende
                parzelle {
                    stellplatzparzelle
                    verwaltungsparzelle
                }
            }
            kfzKennzeichen
        }
    }
`;



// Für Telefonliste gehen wir über Pacht
export const GET_PACHT = gql`
    query GetPacht {
        pacht {
            pachtende
            paechter {
                name
                vorname
                telefonFestnetz
                telefonMobil
                weitereKontaktpersonen
            }
            parzelle {
                stellplatzparzelle
                verwaltungsparzelle
            }
        }
    }
`;



// Für Eichdaten gehen wir über die Zähler
export const GET_ZAEHLER = gql`
    query GetZaehler {
        zaehler {
            energieart
            parzelle {
                standort
                pachtSet {
                    paechter {
                        name
                        vorname
                        stromzaehlerart
                    }
                    pachtende
                }
                schacht
                verwaltungsparzelle
            }
            letzteEichung
            deinstallationAm
            zaehlernummer
        }
    }
`;



// Für Gasdruckprüfungen gehen wir über Parzellen
export const GET_PARZELLEN = gql`
    query GetParzellen {
        parzellen {
            standort
            geplanteGasdruckpruefung
            gasdruckpruefung
            pachtSet {
                paechter {
                    name
                    vorname
                }
                pachtende
            }
            besonderheiten
            stellplatzparzelle
        }
    }
`;


// Für Eichdaten gehen wir über die Zähler
export const GET_DATEN_LISTE_ZAEHLERSTAENDE = gql`
    query GetDatenListeZaehlerstaende {
        datenListeZaehlerstaende {
            paechterName
            paechterVorname
            standort
            stromAnfangsstand
            stromEndstand
            stromStandBeiWechsel
            stromStandNachWechsel
            gasAnfangsstand
            gasEndstand
            gasStandBeiWechsel
            gasStandNachWechsel
            wasserAnfangsstand
            wasserEndstand
            wasserStandBeiWechsel
            wasserStandNachWechsel
        }
    }
`;


// Daten für Liste Energieverbraeuche
export const GET_ENERGIEVERBRAEUCHE = gql`
    query GetEnergieverbraeuche {
        energieverbraeuche {
            paechter
            parzelle
            energieart
            verbrauch
            pacht {
                pachtende
                kontierungsnummer
            }
            abrechnungsperiode
            verwaltungsparzelle
            zaehler {
                zaehlernummer
            }
        }
    }
`;

// Daten für Liste Energieverbraeuche
export const GET_GESAMTVERBRAEUCHE = gql`
    query GetGesamtverbraeuche {
        gesamtverbraeuche {
            abrechnungsperiode
            gesamtverbrauchGas
            gesamtverbrauchStrom
            gesamtverbrauchWasser
        }
    }
`;



