import * as React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { LOGIN } from './graphql';

// Das zweite Authentication-Backend einbinden, damit filedown-/uploads über die
// "herkömmliche django-Methode", also nicht über JWT sondern über ein Session-Cookie,
// geschützt werden können
import { MODELBACKEND } from './graphql';

import { GET_USERINFOS } from './graphql';
import AlertMessage from '../Alerts/AlertMessage';
import { useNavigate } from "react-router-dom";

import UserContext from './UserContext';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

/*
// Get data of logged-in user
function GetUserData() {
    const { loading, error, data, refetch } = useQuery(GET_USERINFOS, {
        onCompleted: () => {
            console.log(data);
        }
    });

    if (error) {
        console.log(error);
        return <p>Error :(</p>;
    }
    return data;
}
*/

export default function Login() {

    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');

    // use useContext to store state of user "globally"
    const [user, setUser] = React.useContext(UserContext);

    const navigate = useNavigate();

    // State for password visibility
    const [showPassword, setShowPassword] = React.useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Mutation for modelbackend login
    const [login_modelbackend, { mbdata, mberror }] = useMutation(MODELBACKEND, {
        onError: ({ graphQLErrors, networkError, error }) => {
            if (error) {
                let tm = JSON.stringify(error);
                console.log(tm);
                setAlertMessage('Fehler: ' + error.errors?.message || 'Unbekannter Fehler');
                setAlertSeverity('error');
                setOpenAlert(true);
            }

            if (graphQLErrors) {
                console.log('graphql-Fehler: ', graphQLErrors);
            }

            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }

            // If any error appears
            setAlertMessage('Technischer Fehler! Login über modelbackend nicht erfolgreich!');
            setAlertSeverity('error');
            setOpenAlert(true);
        },
        onCompleted: (mbdata) => {
            console.log('Login über modelbackend erfolgreich');
        }
    });

    // Mutation for JWT login
    const [login, { data, loading, error, refetch }] = useMutation(LOGIN, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        onError: ({ graphQLErrors, networkError, error }) => {
            if (error) {
                let tm = JSON.stringify(error);
                console.log(tm);
                setAlertMessage('Fehler: ' + error.errors?.message || 'Unbekannter Fehler');
                setAlertSeverity('error');
                setOpenAlert(true);
            }

            if (graphQLErrors) {
                console.log('graphql-Fehler: ', graphQLErrors);
            }

            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }

            // If any error appears
            setAlertMessage('Login nicht erfolgreich. Benutzername und/oder Passwort nicht korrekt.');
            setAlertSeverity('error');
            setOpenAlert(true);
        },
        onCompleted: (data) => {
            let returnedToken = "";
            try {
                returnedToken = Boolean(data.tokenAuth.token);
            } catch (error) {
                returnedToken = false;
            }

            if (Boolean(returnedToken)) {
                if (openAlert) {
                    setOpenAlert(false);
                }
                setUser({ email: data.tokenAuth.payload.email });
                // Set global user state (extend if needed)
                // setUser({ email: data.tokenAuth.payload.email, firstName: userData.loggedInUser.firstName, lastName: userData.loggedInUser.lastName });

                setTimeout(() => {
                    navigate(`/`);
                }, 100);
            } else {
                setAlertMessage('Login nicht erfolgreich. Benutzername und/oder Passwort nicht korrekt.');
                setAlertSeverity('error');
                setOpenAlert(true);
            }
        }
    });

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        // Login über modelbackend (um files abzusichern)
        login_modelbackend({
            variables: {
                username: data.get('email'),
                password: data.get('password'),
            }
        });

        // Login über JWT (um graphql abzusichern)
        login({
            variables: {
                email: data.get('email'),
                password: data.get('password'),
            }
        });

        // Uncomment for fetching user info after login
        // const userData = GetUserData();
        // console.log(userData);
        // setUser({ firstName: userData.loggedInUser.firstName, lastName: userData.loggedInUser.lastName, email: userData.loggedInUser.email });
    };

    return (
        <>
            <AlertMessage
                message={alertMessage}
                severity={alertSeverity}
                isOpenAlert={openAlert === true}
                handleCloseAlertButton={() => {
                    setOpenAlert(false);
                    console.log('close fired');
                }}
            />

            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                variant="filled"
                                fullWidth
                                id="email"
                                label="Benutzername"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                variant="filled"
                                fullWidth
                                name="password"
                                label="Passwort"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleTogglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Login
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}
